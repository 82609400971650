// 免费视频课相关接口
import request from '@/request/request'

// 视频分类添加
export const AddFreeVideoSort = (params) => request.post(`/adminv2/addFreeVideoSort`, params);

// 视频分类编辑
export const EditFreeVideoSort = (params) => request.post(`/adminv2/editFreeVideoSort`, params);

// 视频分类删除
export const DeleteFreeVideoSort = (params) => request.post(`/adminv2/deleteFreeVideoSort`, params);

// 视频分类列表
export const FreeVideoSortList = (params) => request.post(`/adminv2/freeVideoSortList`, params);

// 视频新增
export const AddFreeVideo = (params) => request.post(`/adminv2/addFreeVideo`, params);

// 视频编辑
export const EditFreeVideo = (params) => request.post(`/adminv2/editFreeVideo`, params);

// 视频详情
export const GetFreeVideo = (params) => request.post(`/adminv2/getFreeVideo`, params);

// 视频下架
export const DownFreeVideo = (params) => request.post(`/adminv2/downFreeVideo`, params);

// 视频列表
export const FreeVideoList = (params) => request.post(`/adminv2/freeVideoList`, params);

// 视频删除
export const DeleteFreeVideo = (params) => request.post(`/adminv2/deleteFreeVideo`, params);

// 添加免费音频
export const addDailyReadingAPI = (params) => request.post(`/adminv2/dailyreading/add`, params);
// 编辑免费音频
export const editDailyReadingAPI = (params) => request.post(`/adminv2/dailyreading/edit`, params);
// 获取免费视频列表
export const getFreeAudioListAPI = (params) => request.post(`adminv2/dailyreading/getLists`, params);
// 获取免费视频详情
export const getFreeAudioDetailAPI = (params) => request.post(`/adminv2/dailyreading/getOne`, params);
// 听课人数获取
export const getReadingCountAPI = (params) => request.post(`/adminv2/dailyreading/getReadingCount`, params);
// 完播次数
export const getCompleteCountAPI = (params) => request.post(`/adminv2/dailyreading/getCompleteCount`, params);
