<template>
    <!-- 客户管理页面 -->
    <div id="client_manage" class="padding24 color_white">
        <!-- 顶部 -->
        <div class="top">
            <div class="top_left"></div>
            <div class="top_rigiht">
                <a-input ref="userNameInput" v-model="searchVal" placeholder="请输入用户信息" style="width: 200px" @pressEnter="searchClick">
                    <a-icon slot="prefix" type="search"/>
                </a-input>
                <a-button :disabled="isDisableSearch" icon="search" type="primary" @click="searchClick">搜索</a-button>
            </div>
        </div>
        <!-- 表格 -->
        <a-table :columns="columns" :data-source="tableData" :loading="tableLoading" :pagination="false">
            <img slot="imageUrl" slot-scope="imageUrl, record" v-viewer :src="record.imageUrl" class="avatar"/>
            <span slot="sex" slot-scope="sex, record">
                <span v-show="record.sex == 1">男</span>
                <span v-show="record.sex == 2">女</span>
                <span v-show="record.sex == 3">保密</span>
            </span>
            <span slot="orderNum" slot-scope="orderNum, record">
                <span style="margin-right: 20px">{{ record.orderNum }}</span>
                <a v-show="record.orderNum > 0 && isShowOrderDetail" @click="detailClick(record)">详情</a>
            </span>
            <span slot="operation" slot-scope="operation, record">
<!--                <a v-show="record.enableFlag == 2 && isShowEnable" @click="isEnabledClick(record)" class="margin_right20">启用</a>-->
<!--                <a v-show="record.enableFlag == 1 && isShowDiable" @click="isEnabledClick(record)" class="margin_right20">禁用</a>-->
                <a @click="isPassWordClick(record)" class="margin_right10">修改密码</a>
                <a @click="isTransferClick(record)">转移权益</a>
            </span>
        </a-table>
        <!-- 分页功能 -->
        <MyPagination v-if="tableData.length>0" :count="count" :pageNo="pageNo" @showSizeChangeFn="showSizeChangeFn"/>
        <!-- 返回顶部 -->
        <a-back-top :target="targetFn" :visibilityHeight="100"/>
        <!-- 转移权益 -->
        <a-modal v-model="visible" title="转移权益" @ok="handleOk" @cancel="handleCancel">
            <a-form-model v-if="visible" ref="ruleForm" :model="transfer" :rules="rules" >
                <a-form-model-item ref="newUserName" label="接受者手机号" prop="newUserName">
                    <a-input v-model="transfer.newUserName" placeholder="请输入接受者手机号" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>

<script>
import {
    QueryAdminUserInfosApi,
    UserEnableApi,
    UserDisabledApi, AetPasswordApi, MoveUserRights,
} from "@/request/api/crm";
import MyPagination from "@/components/pagination/MyPagination";
import {message, Modal} from "ant-design-vue";
import {codeFn} from "@/utils/tools";
import md5 from 'js-md5'
import axios from "axios";
import {domainName} from "@/config/index";
import {AddFreeVideoSort, EditFreeVideoSort} from "@/request/api/videoManage";
export default {
    created() {
        let phone = localStorage.getItem("phone");
        if (phone) {
            this.searchVal = phone;
        } else {
            this.searchVal = "";
        }
        setTimeout(() => {
            localStorage.removeItem("phone");
        }, 3000);
        if (codeFn("/admin/app/user/disabled")) this.isShowDiable = true;
        if (codeFn("/admin/app/user/enable")) this.isShowEnable = true;
        if (codeFn("orderformList")) this.isShowOrderDetail = true;
    },
    components: {MyPagination},
    data() {
        return {
            visible:false,
            isDisableSearch: false, // 搜索按钮是否禁用
            tableLoading: false, // 表格loading图
            count: 0, // 列表的总条数
            pageNo: 1,
            pageSize: 20,
            startTime: "",
            endTime: "",
            searchVal: "",
            columns: [
                {
                    title: "账号",
                    dataIndex: "userName",
                    key: "userName",
                    scopedSlots: {customRender: "userName"},
                },
                {
                    title: "头像",
                    dataIndex: "imageUrl",
                    key: "imageUrl",
                    scopedSlots: {customRender: "imageUrl"},
                },
                {
                    title: "昵称",
                    dataIndex: "nickName",
                    key: "nickName",
                    scopedSlots: {customRender: "nickName"},
                },
                {
                    title: "年级",
                    dataIndex: "gradeText",
                    key: "gradeText",
                    scopedSlots: {customRender: "gradeText"},
                },
                {
                    title: "学号",
                    dataIndex: "userNo",
                    key: "userNo",
                    scopedSlots: {customRender: "userNo"},
                },
                {
                    title: "性别",
                    dataIndex: "sex",
                    key: "sex",
                    scopedSlots: {customRender: "sex"},
                },
                {
                    title: "生日",
                    dataIndex: "birthday",
                    key: "birthday",
                    scopedSlots: {customRender: "birthday"},
                },
                {
                    title: "所在城市",
                    dataIndex: "city",
                    key: "city",
                    scopedSlots: {customRender: "city"},
                },
                {
                    title: "学币金额",
                    dataIndex: "availableBalance",
                    key: "availableBalance",
                    scopedSlots: {customRender: "availableBalance"},
                },
                {
                    title: "购课信息",
                    dataIndex: "orderNum",
                    key: "orderNum",
                    scopedSlots: {customRender: "orderNum"},
                },
                {
                    title: "注册时间",
                    dataIndex: "createTime",
                    width: "12%",
                    sorter: true,
                    key: "createTime",
                    sorter: (a, b) => {
                        let aTime = new Date(a.createTime).getTime();
                        let bTime = new Date(b.createTime).getTime();
                        return aTime - bTime;
                    },
                },
                {
                    title: "操作",
                    key: "operation",
                    width: '160px',
                    dataIndex: "operation",
                    scopedSlots: {customRender: "operation"},
                },
            ],
            tableData: [],
            isShowDiable: false, // 禁用是否隐藏
            isShowEnable: false, // 启用是否隐藏
            isShowOrderDetail: false, // 订单详情是否隐藏
            transfer:{
                userNo:'',
                userName:'',
                newUserName:'',
            },
            rules: {
                newUserName: [
                    { required: true, message: "请输入接受者手机号", trigger: "blur" },
                    { pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/, message: "请输入正确的手机号码", trigger: "blur" }
                ],
            },
        };
    },
    methods: {
        // 回到顶部的函数
        targetFn() {
            return document.querySelector("#client_manage");
        },
        // 注册日期改变
        onChangeDate(date, dateString) {
            if (dateString[0] != "") {
                this.startTime = dateString[0] + " 00:00:00";
                this.endTime = dateString[1] + " 23:59:59";
            } else {
                this.startTime = "";
                this.endTime = "";
            }
        },
        // 搜索的按钮
        searchClick() {
            this.pageNo = 1;
            this.QueryAdminUserInfosFn();
        },
        // 分页功能切换的回调
        showSizeChangeFn(current, pageSize) {
            this.pageNo = current;
            this.pageSize = pageSize;
            this.QueryAdminUserInfosFn();
        },
        // 购课信息详情按钮
        detailClick(record) {
            sessionStorage.setItem("userNo", record.userNo);
            location.href = "/orderformManage/orderformList";
        },
        // 启用禁用的按钮
        isEnabledClick(record) {
            if (record.enableFlag == 2) {
                UserEnableApi({userNo: record.userNo}).then(({code}) => {
                    if (code == 200) {
                        message.success("启用成功", 1);
                        record.enableFlag = 1;
                    }
                });
            } else if (record.enableFlag == 1) {
                Modal.confirm({
                    title: "提示",
                    content: "禁用后该账号无法登陆APP,是否确认禁用",
                    okText: "确认",
                    okType: "danger",
                    cancelText: "取消",
                    width: "500px",
                    onOk() {
                        UserDisabledApi({userNo: record.userNo}).then(({code}) => {
                            if (code == 200) {
                                message.success("禁用成功", 1);
                                record.enableFlag = 2;
                            }
                        });
                    },
                });
            }
        },
        
        // 点击修改密码
        isPassWordClick(record){
            let num = record.userName.substring(record.userName.length - 5)
            let arr = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z']
            let val = ''
            let n = 3
            for(var i = 0; i < n; i++){
                val += arr[Math.floor(Math.random()*26)];
            }
            console.log(md5(val.replace(val[0],val[0].toUpperCase())+num))
            let password = md5(val.replace(val[0],val[0].toUpperCase())+num)
            Modal.confirm({
                title: "请将密码手动复制后提交！",
                content: `修改后的密码为${val.replace(val[0],val[0].toUpperCase())+num}，确认修改吗？`,
                okText: "确认",
                okType: "danger",
                cancelText: "取消",
                width: "500px",
                onOk() {
                    let token = localStorage.getItem("token");
                    let urlLink = domainName
                    if(urlLink != "https://test.shenyiedu.com/"){
                        urlLink = 'https://app.shenyiedu.com/'
                    }
                    let url = `${urlLink}/appv2/login/setPassword`
                    let obj = {
                        userName:record.userName,
                        passWord:password,
                        code:'9628'
                    }
                    axios.post(url,obj,{
                        headers:{
                            'accesstoken':token,
                            'authorization':token
                        }
                    }).then((res) => {
                        if (res.data.code == 200) {
                            message.success("设置成功", 1);
                        }
                    });
                },
            });
        },
        
        // 点击转移权益
        isTransferClick(record){
            this.transfer.userNo = record.userNo
            this.transfer.userName = record.userName
            this.visible = true
        },
        // 转移权益确认按钮
        handleOk(){
            let self = this
            self.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    MoveUserRights(self.transfer).then(({ code, data }) => {
                        if(code == 200){
                            self.visible = false
                            message.success('已成功转移！')
                            self.handleCancel()
                        }else{
                            message.success(data.msg)
                        }
                    });
                }
            });
        },
    
        handleCancel(){
            this.transfer.userNo = ''
            this.transfer.userName = ''
            this.transfer.newUserName = ''
        },
        // 获取用户信息列表函数
        QueryAdminUserInfosFn () {
            if (!this.searchVal) {
                this.tableData = []
                return
            }
            QueryAdminUserInfosApi({
                pageNo: this.pageNo,
                pageSize: this.pageSize,
                startTime: this.startTime, // 搜索开始日期
                endTime: this.endTime, // 搜索结束日期
                keyword: this.searchVal, // 搜索关键字
            }).then(({code, data}) => {
                if (code == 200) {
                    this.tableLoading = false;
                    data.data.forEach((item) => {
                        item.key = item.id;
                        item.gradeText = item.gradeText == "" ? "——" : item.gradeText;
                        item.userName = item.userName == "" ? "——" : item.userName;
                        item.birthday = item.birthday.substring(0, 10);
                        item.city =
                            item.province == "北京市"
                                ? item.province
                                : item.province + item.city;
                    });
                    this.tableData = data.data;
                    this.count = data.count;
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
#client_manage {
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
}

.top {
    display: flex;    
    .top_rigiht {
        span {
            margin-right: 10px;
            /deep/ .ant-calendar-picker-input {
                margin-left: 10px;
            }
        }
    }
}

.avatar {
    width: 80px;
    height: 80px;
    object-fit: contain;
}

/deep/ .ant-table-wrapper {
    margin-top: 20px;
}

/deep/ .ant-table-thead > tr > th {
    font-weight: 600;
}
</style>
